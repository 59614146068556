import { Link } from 'react-router-dom'
import Container from '../layout/Container';
import Separator from '../layout/Separator';
import Group from '../layout/Group';

import bauwensLogo from '../../assets/logos/bauwens.svg';
import ampureLogo from '../../assets/logos/ampure.svg';

function Footer() {

    return (
        <footer className="site-footer">
            <Container>
                <Separator />
                <Group className="site-footer__wrapper">
                    <Group className="site-footer__navigation">
                        <ul>
                            <li><Link to="/de/datenschutz">Datenschutz</Link></li>
                            <li><Link to="/de/impressum">Impressum</Link></li>
                            {/* <li><Link to="/de/kontakt">Kontakt</Link></li> */}
                        </ul>
                    </Group>
                    <Group className="site-footer__logos">
                        <a href="https://www.bauwens.de/" target="_blank" rel="noreferrer">
                            <img src={bauwensLogo} alt="Bauwens Logo" loading='lazy' />
                        </a>
                        <a href="https://ampure.de/" target="_blank" rel="noreferrer">
                            <img src={ampureLogo} alt="Ampure Logo" loading='lazy' />
                        </a>
                    </Group>
                </Group>

            </Container>
        </footer>
    )
}

export default Footer