import { Helmet } from "react-helmet";
import { useLayoutEffect, useRef, useState } from "react";
import { gsap } from "gsap";
import { useLocation } from "react-router-dom";
import { ScrollTrigger } from "gsap/ScrollTrigger";

import Container from "../../components/layout/Container";
import Section from "../../components/layout/Section";
import Group from "../../components/layout/Group";
import Collage from "../../components/layout/Collage";
import Button from "../../components/layout/Button";
import Quote from "../../components/layout/Quote";
import SVGHeading from "../../components/layout/SVGHeading";
import sundowner from "../../assets/quotes/sundowner.jpg";
import nachhaltigkeit from "../../assets/quotes/nachhaltigkeit.jpg";
import ContactBlock from "../../components/layout/ContactBlock";
import Home01 from "../../components/de/Home01";
import Home02 from "../../components/de/Home02";
import Home03 from "../../components/de/Home03";
import Home04 from "../../components/de/Home04";
import Home05 from "../../components/de/Home05";
import theChangeLogo from "../../assets/logos/thechange_logo.svg";
import rethink from "../../assets/images/rethink.svg";
import office from "../../assets/images/office.svg";
import space from "../../assets/images/space.svg";

import heroVideoMp4 from "../../assets/videos/change-header-06-s-lowres.mp4";
import introductionImage from "../../assets/images/introduction.jpg";
import theChange3d from "../../assets/images/the-change-3d.jpg";
import theChangeLage from "../../assets/images/zwischen-natur-und-skyline.jpg";
import NewWorkOpenOffice from "../../assets/images/New_Work_Open_Office.jpg";
import image01 from "../../assets/images/image-1.jpg";
import image02 from "../../assets/images/image-2.jpg";
import image03 from "../../assets/images/image-3.jpg";
import image04 from "../../assets/images/image-4.jpg";
import image05 from "../../assets/images/image-5.jpg";

import video02 from "../../assets/videos/change-home-text-01.mp4";
import heroVideoMp4mobile from "../../assets/videos/change-header-06-mobil-s-lowres.mp4";

import useWindowWidth from "../../hooks/useWindowWidth";

function Home() {
  const main = useRef();
  const videoOverlayRef = useRef();

  const { pathname } = useLocation();

  const [introductionHeight, setIntroductionHeight] = useState("");
  const introductionRef = useRef(null);

  introductionRef.current = 0;

  const windowWidth = useWindowWidth();

  useLayoutEffect(() => {
    setIntroductionHeight(() => {
      if (introductionRef.current.offsetWidth > 1080) {
        return introductionRef.current.offsetHeight;
      } else {
        return introductionRef.current.offsetWidth / 2;
      }
    });

    const ctx = gsap.context((self) => {
      const pinnedSection = document.querySelector(".pinned-section");

      if (pinnedSection && windowWidth > 480) {
        gsap.to([".pinned-section > video", ".hero__overlay"], {
          scrollTrigger: {
            trigger: pinnedSection,
            start: "bottom bottom",
            end: "bottom top",
            scrub: true,
            pin: true,
          },
          yPercent: -50,
        });
      }

      if (videoOverlayRef.current) {
        // Assuming you have an array of image elements in `images`:
        const images = Array.from(
          videoOverlayRef.current.querySelectorAll("img")
        );

        // Set initial opacity to 0 for all images.
        gsap.set(images, { opacity: 0 });

        // Define the animation timeline.
        const timeline = gsap.timeline({ repeat: -1, ease: "power2.inOut" });

        timeline
          // The Change 01:00
          .to(
            images[0],
            {
              opacity: 1,
              duration: 1,
            },
            "+=1"
          )
          .to(
            images[0],
            {
              opacity: 0,
              duration: 1,
            },
            "+=2"
          )
          // Rethink. 07:00
          .to(
            images[1],
            {
              opacity: 1,
              duration: 1,
            },
            "+=1"
          )
          .to(
            images[1],
            {
              opacity: 0,
              duration: 1,
            },
            "+=2"
          )
          // Office. 10:00
          .to(
            images[2],
            {
              opacity: 1,
              duration: 1,
            },
            "+=1"
          )
          .to(
            images[2],
            {
              opacity: 0,
              duration: 1,
            },
            "+=2"
          )
          // Space. 15:00
          .to(
            images[3],
            {
              opacity: 1,
              duration: 1,
            },
            "+=1"
          )
          .to(
            images[3],
            {
              opacity: 0,
              duration: 1,
            },
            "+=3"
          );

        // Start the timeline.
        timeline.play();
      }
    }, main); // <- Scope!

    return () => {
      ctx.revert();
      setTimeout(() => {
        ScrollTrigger.refresh();
      }, 300);
    }; // <- Cleanup!
  }, [pathname, windowWidth]);

  return (
    <main className="site-main home-page" ref={main}>
      <Helmet>
        <title>The Change</title>
        <meta name="title" content="The Change" />

        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://thechange-eschborn.de/de/" />
        <meta property="og:title" content="The Change" />

        <meta
          property="twitter:url"
          content="https://thechange-eschborn.de/de/"
        />
        <meta property="twitter:title" content="The Change" />
      </Helmet>
      <Section className="hero pinned-section">
        <video className="hero__video" playsInline autoPlay muted loop>
          {windowWidth < 480 ? (
            <source src={heroVideoMp4mobile} type="video/mp4" />
          ) : (
            <source src={heroVideoMp4} type="video/mp4" />
          )}
        </video>
        <div className="hero__overlay">
          <div ref={videoOverlayRef}>
            <img
              src={theChangeLogo}
              alt="The Change"
              style={{ opacity: 0, transition: "opacity 375ms ease-in-out" }}
            />
            <img
              src={rethink}
              alt="Rethink."
              style={{ opacity: 0, transition: "opacity 375ms ease-in-out" }}
            />
            <img
              src={office}
              alt="Office."
              style={{ opacity: 0, transition: "opacity 375ms ease-in-out" }}
            />
            <img
              src={space}
              alt="Space."
              style={{ opacity: 0, transition: "opacity 375ms ease-in-out" }}
            />
          </div>
        </div>
      </Section>

      <Section className="introduction">
        <Container innerRef={introductionRef}>
          <SVGHeading alt="Time for a Change" style={{ maxWidth: "860px" }}>
            <Home01 />
          </SVGHeading>
          <Group style={{ maxWidth: "620px", marginTop: "var(--padding)" }}>
            <h2 className="is-font-size-m is-font-family-regular">
              Konzipiert mit den fortschrittlichsten Baustoffen unserer Zeit:
              Mut, Verantwortung und Identität
            </h2>
            <p className="is-font-family-light" style={{ maxWidth: "386px" }}>
              Wichtige Ökonomen reden von Purpose, auf der Vorstandsagenda steht
              agiles Arbeiten, immer mehr Stakeholder erheben Nachhaltigkeit zur
              strategischen Position. Wenn das also die große Transformation
              ist, was brauchen Unternehmen, um sie wirklich umzusetzen? Wandel
              braucht Raum, der Wandel ermöglicht. Diesem Prinzip folgt The
              Change. In allen Dimensionen, die eine konsequente Realisierung
              braucht. In allen Facetten, die Ihrem individuellen Wandel
              realisierbare Möglichkeiten bietet.
            </p>
            <Button to="/de/objekt">Objekt</Button>
          </Group>
        </Container>
        <img
          style={{ marginTop: "-" + introductionHeight + "px" }}
          className="background"
          src={introductionImage}
          alt="Rendering: The Change"
          loading="lazy"
        />
      </Section>

      <Section>
        <Container className="media-text">
          <Group style={{ flexBasis: "530px", flexGrow: 1 }}>
            <h2 className="is-font-size-m is-font-family-regular">
              Das Office von morgen wird nicht mehr in Quadratmetern gemessen.
            </h2>
            <p>
              Der neue Maßstab heißt Haltung. Eine Haltung, die wirtschaftliche
              Aspekte mit Raum für neue Arbeitsmöglichkeiten und einem
              fundamentalen Statement für Nachhaltigkeit verbindet. Sichtbar,
              spürbar und gut für die Bilanzen – für die Natur ebenso wie für
              die Ergebnisse Ihrer Öffentlichkeitsarbeit.
            </p>
          </Group>
          <Group style={{ flexBasis: "200px", flexGrow: 9999 }}>
            <img
              src={NewWorkOpenOffice}
              alt="New Work Open Office"
              loading="lazy"
            />
          </Group>
        </Container>
      </Section>

      <Quote
        quoteBg={sundowner}
        alt="Vom Rooftop auf neue Möglichkeiten blicken"
      >
        <SVGHeading
          alt="Vom Rooftop auf neue Möglichkeiten blicken"
          style={{ maxWidth: "667px" }}
        >
          <Home02 />
        </SVGHeading>
      </Quote>

      <Section className="arbeitswelten">
        <Container>
          <SVGHeading
            alt="Ein Gebäude, das Scrum kann"
            style={{ maxWidth: "849px" }}
          >
            <Home03 />
          </SVGHeading>
        </Container>
        <Container className="media-text no-padding-right">
          <Group>
            <h2 className="is-font-size-m is-font-family-regular">
              Raumaufteilung, die sprinten, denken und kooperieren kann.
            </h2>
            <p className="is-font-family-light">
              Wer New Work bei Google sucht, findet mehr als 18 Milliarden
              Treffer. Dass sich die Arbeitswelt verändert, ist keine Frage
              mehr. Wie sie sich ändert, ist ein Prozess. Wir wissen so gut wie
              Sie, dass dieser Prozess Flexibilität braucht. Denn zwischen
              Scrum, Activity Based Working, Social Hubs und Desk Sharing muss
              es ausreichend Raum für individuelle Anpassung und Bewegung geben.
            </p>
            <Button to="/de/arbeitswelten">Arbeitswelten</Button>
          </Group>
          <Group>
            <img src={theChange3d} alt="" loading="lazy" />
          </Group>
        </Container>
      </Section>

      <Section
        className="nachhaltigkeit"
        style={{
          backgroundImage: `url(${nachhaltigkeit})`,
          backgroundSize: "cover",
          backgroundPosition: "center bottom",
        }}
      >
        <Container>
          <SVGHeading alt="Erde und Sonne" style={{ maxWidth: "602px" }}>
            <Home04 />
          </SVGHeading>
          <Group
            style={{
              maxWidth: "626px",
              marginTop: "var(--padding)",
              color: "var(--primary)",
            }}
          >
            <h2 className="is-font-size-m is-font-family-regular">
              Mit den besten Energieversorgern des gesamten Universums: Erde und
              Sonne.
            </h2>
            <p className="is-font-family-light" style={{ maxWidth: "386px" }}>
              Wärme aus der Erde, Strom von der Sonne und sehr smarte Ideen aus
              unserem Anspruch: So sieht das Energiekonzept von The Change aus.
              Denn wir wollten die regenerativen Energiequellen der Geothermie
              und der Photovoltaik mit intelligenter Gebäudeplanung zu
              Höchstleistungen bringen.
            </p>
            <Button to="/de/nachhaltigkeit" color="black">
              Nachhaltigkeit
            </Button>
          </Group>
        </Container>
      </Section>

      <Section className="lage">
        <Container>
          <SVGHeading
            alt="Eschborn Born for Change"
            style={{ maxWidth: "918px" }}
          >
            <Home05 />
          </SVGHeading>
        </Container>
        <Container className="media-text no-padding-right">
          <Group>
            <p className="is-font-family-light">
              Die Stadt Eschborn bietet in vieler Hinsicht beste Bedingungen für
              eine neue Balance aus Arbeiten, Leben und Erholen. Allem voran
              natürlich die Lage: Hier die Frankfurter Skyline, dort die grünen
              Hügel des Taunus. Hier die Nähe zur Kultur einer Weltstadt, dort
              Wälder, die Ufer des Mains oder die exquisite Weinkultur des
              Rheingaus. Und Eschborn immer mittendrin. Als attraktiver Standort
              aus unternehmerischer Sicht und als einladender Lebenspunkt aus
              ganz persönlicher Perspektive.
            </p>
            <Button to="/de/lage">Lage</Button>
          </Group>
          <Group>
            <img src={theChangeLage} alt="Standort The Change" loading="lazy" />
          </Group>
        </Container>
      </Section>

      <Section>
        <video src={video02} autoPlay playsInline loop muted />
      </Section>

      <Collage
        images={[image01, image02, image03, image04, image05]}
        phrases={[
          "flexibel",
          "regenerativ",
          "energieeffizient",
          "aufenthaltsqualität",
          "hybridbauweise",
          "nachhaltigkeit",
          "raumatmosphäre",
        ]}
      />

      <ContactBlock hasAddress />
    </main>
  );
}

export default Home;
