import Container from "./Container"
import Section from "./Section"

function Quote({ children, quoteBg, alt = '' }) {
    return (
        <Section className="quote">
            <img className="quote__background" src={quoteBg} alt={alt} loading='lazy' />
            <Container>
                {children}
            </Container>
        </Section>
    )
}

export default Quote