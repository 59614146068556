import { NavLink, useLocation } from 'react-router-dom'

function Header() {

    const location = useLocation();

    let currentSite = location.pathname.substring(3);

    document.documentElement.lang = 'en';

    return (
        <header className="site-header">
            <div className="container">
                <nav>
                    <ul>
                        <li><NavLink to="/en/">Home</NavLink></li>
                        <li><NavLink to="/en/showroom">Showroom</NavLink></li>
                        <li><NavLink to="/en/property">Property</NavLink></li>
                        <li><NavLink to="/en/contact">Contact</NavLink></li>
                        <li><NavLink to={"/de" + currentSite} >DE</NavLink></li>
                    </ul>
                </nav>
            </div>
        </header>
    )
}

export default Header