function Footer() {
    return (
        <footer className="site-footer">
            <div className="container">
                Footer
            </div>
        </footer>
    )
}

export default Footer