import Group from "./Group"
import Section from "./Section"
import Container from "./Container"
import Button from "./Button"
import contactImage from "../../assets/images/contactImage.jpg";
import { Link } from "react-router-dom"

function Crosslinks({ title, href, linkTitle, image }) {
    return (
        <Section className="crosslinks">
            <Container>
                <Group>
                    <Link to="/de/kontakt">
                        <img src={contactImage} alt="" loading='lazy' />
                    </Link>
                    <h3 className="is-font-family-regular is-font-size-m">Get in touch</h3>
                    <Button to="/de/kontakt">Kontakt</Button>
                </Group>
                <Group>
                    <Link to={href}>
                        <img src={image} alt="" loading='lazy' />
                    </Link>
                    <h3 className="is-font-family-regular is-font-size-m">{title}</h3>
                    <Button to={href}>{linkTitle}</Button>
                </Group>
            </Container>
        </Section>
    )
}

export default Crosslinks