import { useState } from "react";
import Container from "../../components/layout/Container";
import Section from "../../components/layout/Section";
import Group from "../../components/layout/Group";
import Separator from "../../components/layout/Separator";

function FloorplanViewer({ floorplans }) {
  const firstFloor = floorplans[0];

  const [currentFloorplan, setCurrentFloorplan] = useState(
    firstFloor?.floorplan || null
  );
  const [currentStackingplan, setCurrentStackingplan] = useState(
    firstFloor?.stack || null
  );

  function handleFloorClick(floorplan, stack) {
    setCurrentFloorplan(floorplan);
    setCurrentStackingplan(stack);
  }

  return (
    <Section className="floorplan-viewer">
      <Container style={{ paddingBottom: 0, paddingTop: 0 }}>
        <Group className="floorplan-viewer__controls">
          {floorplans.map(({ name, floorplan, stack }) => (
            <button
              className={`${floorplan === currentFloorplan ? "active" : ""}`}
              key={floorplan}
              onClick={() => handleFloorClick(floorplan, stack)}
            >
              {name}
            </button>
          ))}
        </Group>
        <Separator />
      </Container>

      <Container className="floorplan-viewer__container">
        <Group className="floorplan-viewer__floors">
          <img src={currentFloorplan} alt="" />
        </Group>
        <Group className="floorplan-viewer__stack">
          <img src={currentStackingplan} alt="" />
        </Group>
      </Container>
    </Section>
  );
}

export default FloorplanViewer;
