import { Outlet } from "react-router-dom";
import Header from "../../components/de/Header";
import Footer from "../../components/de/Footer";
import { useEffect } from "react";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { useLocation } from "react-router-dom";

import Lenis from "@studio-freight/lenis";
import { Helmet } from "react-helmet";
import preview from "../../assets/images/preview.png";

gsap.registerPlugin(ScrollTrigger);

const SharedLayout = () => {
  const { pathname } = useLocation();

  const lenis = new Lenis({
    lerp: 0.25,
  });

  lenis.on("scroll", ScrollTrigger.update);

  gsap.ticker.add((time) => {
    lenis.raf(time * 1000);
  });

  gsap.ticker.lagSmoothing(0);

  // Scroll to top on route change
  useEffect(() => {
    document.documentElement.scrollTo({
      top: 0,
      left: 0,
      behavior: "instant",
    });

    // Refresh ScrollTrigger after images have loaded
    document.querySelectorAll("img").forEach((img) => {
      if (img.complete) {
        ScrollTrigger.refresh();
      } else {
        img.addEventListener("load", (imgLoaded) => ScrollTrigger.refresh());
      }
    });
  }, [pathname]);

  return (
    <>
      <Header />
      <Helmet>
        <meta
          name="description"
          content="Mit dem „Change“ wird ein flexibles, nachhaltiges Office-Gebäudekonzept mit außergewöhnlich hoher Aufenthaltsqualität für die Mieter geschaffen."
        />
        <meta property="og:type" content="website" />
        <meta
          property="og:description"
          content="Mit dem „Change“ wird ein flexibles, nachhaltiges Office-Gebäudekonzept mit außergewöhnlich hoher Aufenthaltsqualität für die Mieter geschaffen."
        />
        <meta property="og:image" content={preview} />
        <meta property="twitter:card" content="summary_large_image" />
        <meta
          property="twitter:description"
          content="Mit dem „Change“ wird ein flexibles, nachhaltiges Office-Gebäudekonzept mit außergewöhnlich hoher Aufenthaltsqualität für die Mieter geschaffen."
        />
        <meta property="twitter:image" content={preview} />
      </Helmet>
      <Outlet />
      <Footer />
    </>
  );
};

export default SharedLayout;
