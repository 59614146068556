import { Outlet } from "react-router-dom";
import Header from "../../components/en/Header";
import Footer from "../../components/en/Footer";

const SharedLayout = () => {
    return (
        <>
            <Header />
            <Outlet />
            <Footer />
        </>
    );
};

export default SharedLayout;