import Container from '../../components/layout/Container';

function Showroom() {
    return (
        <main className="site-main showroom-page">
            <Container>
                <h1>Showroom</h1>
            </Container>
        </main>
    )
}

export default Showroom