import { NavLink, useLocation } from 'react-router-dom'
import pattern from '../../assets/patterns/large_gray.svg';
import { useEffect, useState, useRef } from 'react';
import Container from '../layout/Container';
import Section from '../layout/Section';
import Button from '../layout/Button';
import logo from '../../assets/logos/thechange-small.svg';
import gsap from 'gsap';

function Header() {
    const location = useLocation();
    const siteHeaderRef = useRef();

    // let currentSite = location.pathname.substring(4);

    document.documentElement.lang = 'de';

    const [burger, setBurger] = useState(false);

    useEffect(() => {
        let ctx = gsap.context(() => {
            // Check if the pathname is "/de/"
            if (location.pathname === '/de/') {
                // If it's "/de/", create a ScrollTrigger animation to show the menu
                gsap.to(siteHeaderRef.current, {
                    scrollTrigger: {
                        trigger: document.querySelector(".introduction"),
                        start: "top top",
                        toggleActions: "play none none reverse",
                    },
                    opacity: 1
                });
            } else {
                // For all other paths, show the menu by default
                gsap.set(siteHeaderRef.current, { opacity: 1 });
            }

        }, siteHeaderRef); // <- scopes all selector text inside the context to this component (optional, default is document)

        return () => ctx.revert(); // cleanup! 

    }, [location.pathname]);

    const handleLogoClick = () => {
        setBurger(false);
        window.scrollTo({
            top: 0,
            behavior: "smooth",
        });
    }

    return (
        <>
            <header className="site-header" ref={siteHeaderRef} style={{ opacity: 0 }}>
                <Section>
                    <Container className="site-header__head">
                        <nav className="site-header__main-nav">
                            <ul>
                                <li><NavLink to="/de/objekt">Objekt</NavLink></li>
                                <li><NavLink to="/de/arbeitswelten">Arbeitswelten</NavLink></li>
                                <li><NavLink to="/de/nachhaltigkeit">Nachhaltigkeit</NavLink></li>
                                <li><NavLink to="/de/lage">Lage</NavLink></li>
                                <li><NavLink to="/de/kontakt">Kontakt</NavLink></li>
                                {/* <li><NavLink to={"/en/" + currentSite} >EN</NavLink></li> */}
                            </ul>
                        </nav>
                        <div className="site-header__logo">
                            <NavLink to="/de/" onClick={() => handleLogoClick()}>
                                <img src={logo} alt="The Change Logo" />
                            </NavLink>
                        </div>
                        <div className={`site-header__burger ${burger ? 'site-header__burger--active' : ''}`}
                            onClick={() => setBurger(!burger)}>
                            <div className="burger-line"></div>
                            <div className="burger-line"></div>
                            <div className="burger-line"></div>
                        </div>
                    </Container>
                </Section>
            </header>
            <nav className={`container fluid site-header__nav ${burger ? 'site-header__nav--active' : ''}`} style={{ backgroundImage: `url(${pattern})`, backgroundSize: '15%' }}>
                <Container>
                    <ul onClick={() => setBurger(!burger)}>
                        <li><Button type="navlink" to="/de/objekt">Objekt</Button></li>
                        <li><Button type="navlink" to="/de/arbeitswelten">Arbeitswelten</Button></li>
                        <li><Button type="navlink" to="/de/nachhaltigkeit">Nachhaltigkeit</Button></li>
                        <li><Button type="navlink" to="/de/lage">Lage</Button></li>
                        <li><Button type="navlink" to="/de/kontakt">Kontakt</Button></li>
                        {/* <li><Button type="navlink" to={"/en/" + currentSite} >EN</Button></li> */}
                    </ul>
                </Container>
            </nav>
        </>
    )
}

export default Header