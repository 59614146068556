// import Container from '../../components/layout/Container';

// import { Splide, SplideSlide } from '@splidejs/react-splide';
// // Default theme
// import '@splidejs/react-splide/css';
// // or only core styles
// import '@splidejs/react-splide/css/core';

// import image1 from '../../assets/images/image1.jpg';
// import image2 from '../../assets/images/image2.jpg';
// import image3 from '../../assets/images/image3.jpg';

function Home() {
    return (
        <main className="site-main home-page">

        </main>
    )
}

export default Home