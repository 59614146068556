import { useState } from 'react'
import { Blurhash } from 'react-blurhash'

function Image({ src, alt, className, hash }) {

    const [imageLoaded, setImageLoaded] = useState(false)

    return (
        <>
            <div className={className} style={imageLoaded ? { opacity: 0 } : { opacity: 1 }}>
                <Blurhash hash={hash} width="100%" height="100%" />
            </div>

            <img className={className} style={imageLoaded ? { opacity: 1 } : { opacity: 0 }} onLoad={() => setImageLoaded(true)} src={src} alt={alt} />
        </>
    )
}

export default Image