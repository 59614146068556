import React, { useRef, useLayoutEffect } from "react";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);

function SVGHeading({ children, alt, style }) {
  const main = useRef(null);

  // Scroll animations
  useLayoutEffect(() => {
    const ctx = gsap.context((self) => {
      const animatedSvgs = self.selector(".animated-svg");

      animatedSvgs.forEach((svg) => {
        gsap.from(svg, {
          scrollTrigger: {
            trigger: svg,
            start: "top bottom",
            toggleActions: "play none none none",
          },
          opacity: 0,
          y: 100,
          duration: 0.675,
          ease: "power2.out",
        });
      });
    }, main); // <- Scope!

    return () => {
      ctx.revert();
    }; // <- Cleanup!
  }, []);

  return (
    <div className="svg-heading" style={style} ref={main}>
      <h2 className="svg-heading__text visually-hidden">{alt}</h2>
      {children}
    </div>
  );
}

export default SVGHeading;
