import { Helmet } from "react-helmet";
import Section from "../../components/layout/Section";
import Container from "../../components/layout/Container";
import SVGHeading from "../../components/layout/SVGHeading";
import Group from "../../components/layout/Group";
import Image from "../../components/layout/Image";
import Crosslinks from "../../components/layout/Crosslinks";
import Nachhaltigkeit01 from "../../components/de/Nachhaltigkeit01";

import nachhaltigkeitHeader from "../../assets/images/nachhaltigkeitHeader.jpg";
import nachhaltigkeitIntroductionVideo from "../../assets/videos/change-nachhaltigkeit-03-s.mp4";
import bauweise from "../../assets/images/bauweise.jpg";
import sketch from "../../assets/images/sketch.jpg";
import dgnb from "../../assets/images/dgnb.jpg";
import features from "../../assets/images/features.jpg";
import teamwork from "../../assets/videos/change-nachhaltigkeit-04-s.mp4";
import brunette from "../../assets/images/brunette.jpg";
import collagePattern from "../../assets/patterns/small_green_static.svg";
import venti from "../../assets/images/venti.jpg";
import lebensraum from "../../assets/images/lebensraum.jpg";
import image05 from "../../assets/images/image05.jpg";
import image14 from "../../assets/images/image14.jpg";
import dataImage from "../../assets/images/change-nachhaltigkeit-diagramm.svg";

const Nachhaltigkeit = () => {
  return (
    <main className="site-main nachhaltigkeit-page">
      <Helmet>
        <title>Nachhaltigkeit - The Change</title>
        <meta name="title" content="Nachhaltigkeit - The Change" />

        <meta property="og:type" content="website" />
        <meta
          property="og:url"
          content="https://thechange-eschborn.de/de/nachhaltigkeit"
        />
        <meta property="og:title" content="Nachhaltigkeit - The Change" />

        <meta
          property="twitter:url"
          content="https://thechange-eschborn.de/de/nachhaltigkeit"
        />
        <meta property="twitter:title" content="Nachhaltigkeit - The Change" />
      </Helmet>
      <Section className="hero">
        <Image
          src={nachhaltigkeitHeader}
          className="hero__image"
          alt="Solar Anlagen"
          hash={"LkF=?i-;RjRj~q%MRjWB%Mt7fkWB"}
        />
        <Container>
          <SVGHeading alt="Erde und Sonne" style={{ maxWidth: "742px" }}>
            <Nachhaltigkeit01 />
          </SVGHeading>
        </Container>
      </Section>
      <Section>
        <Container>
          <h2 className="is-font-size-28 is-font-family-regular">
            Mit den besten Energieversorgern des gesamten Universums: Erde und
            Sonne.
          </h2>
          <p className="is-font-size-28" style={{ maxWidth: "900px" }}>
            Wärme aus der Erde, Strom von der Sonne und sehr smarte Ideen aus
            unserem Anspruch: So sieht das Energiekonzept von The Change aus.
            Denn wir wollten die regenerativen Energiequellen der Geothermie und
            der Photovoltaik mit intelligenter Gebäudeplanung zu
            Höchstleistungen bringen.
          </p>
        </Container>
      </Section>

      <Section className="data">
        <Container className="data__container">
          <div className="item" id="item-1" style={{ maxWidth: "400px" }}>
            <p className="key is-font-size-xs">
              Einsparung Endenergie gegenüber GEBÄUDEENERGIEGESETZ
            </p>
            <p className="value is-font-size-l">
              <span className="is-font-size-xl" id="count-1">
                ~ 68 %
              </span>
            </p>
          </div>
        </Container>
        <Container
          style={{ display: "flex", justifyContent: "center", paddingTop: 0 }}
        >
          <Group style={{ width: "750px" }}>
            <img src={dataImage} alt="" />
          </Group>
        </Container>
      </Section>

      <Section>
        <video
          src={nachhaltigkeitIntroductionVideo}
          autoPlay
          playsInline
          loop
          muted
        />
      </Section>

      <Section>
        <Container className="media-text">
          <Group style={{ flexBasis: "400px", flexGrow: 1 }}>
            <p>
              Die Zahlen zeigen, dass das auch gelingt. Denn durch die
              Kombination von Geothermie und reversibler Wärmepumpe können Sie
              sich in Ihren Büros darüber freuen, dass etwa 90 % der
              Jahresheizleistung ressourcenschonend, emissionsfrei und
              weitestgehend autark erfolgen.
            </p>
            <p>
              Und diese Werte hinterlassen nicht nur einen besonders kleinen
              CO₂-Fußabdruck, sondern auch die spannende Frage, wie wir das
              ermöglichen. Schauen Sie dafür am besten nach oben: Als
              Raumklimasystem in den Bürobereichen wird eine wasserdurchströmte
              Heiz-/Kühldecke mit einer hocheffizienten Lufteinführung
              kombiniert. Im Zusammenspiel werden die Speichereigenschaften des
              Betonanteils in der Geschossdecke effektiv genutzt, um Wärmelasten
              abzuführen. Zugleich werden auf diese Weise hohe Kühllasten
              bewältigt.
            </p>
            <p>
              In Summe werden mit diesem Energiekonzept alle Vorgaben des
              Gebäudeenergiegesetzes (GEG) unterschritten.
            </p>
          </Group>
          <Group style={{ flexBasis: "400px", flexGrow: 1 }}>
            <img src={bauweise} alt="Skizze The Change" loading="lazy" />
          </Group>
        </Container>
      </Section>

      <Section>
        <img src={sketch} alt="" loading="lazy" />
      </Section>

      <Section>
        <Container className="collage collage--default">
          <Group>
            <video src={teamwork} playsInline autoPlay loop muted />
          </Group>
          <Group>
            <img src={collagePattern} alt="" loading="lazy" />
          </Group>
          <Group>
            <img src={brunette} alt="" loading="lazy" />
          </Group>
        </Container>
      </Section>

      <Section>
        <Container className="media-text no-padding-right">
          <Group style={{ flexBasis: "400px", flexGrow: 1 }}>
            <h2 className="is-font-size-m is-font-family-regular">
              Wir widmen diese Auszeichnung dem wichtigsten Hauptdarsteller:
              Unsere Erde.
            </h2>
            <p>
              Um Nachhaltigkeit messbar zu machen, hat die Deutsche Gesellschaft
              für Nachhaltiges Bauen das inzwischen führende
              Zertifizierungssystem entwickelt. The Change strebt den höchsten
              DGNB Standard mit einer Platin-Zertifizierung an. Darüber hinaus
              wird mit einer ESG-Verifikation die Konformität zur EU-Taxonomie
              überprüft.
            </p>
            <p>
              Das DGNB System bewertet nicht einzelne Maßnahmen, sondern anhand
              von Kriterien die Gesamtperformance eines Gebäudes. Es fördert die
              Umsetzung einer ganzheitlichen Qualität in Planung, Bau und
              Betrieb. So können Nebenkosten langfristig reduziert werden.
            </p>
            <p>
              Zudem liegt der Fokus auf dem Wohlbefinden und der Gesundheit der
              Gebäudenutzerinnen und -nutzer. DGNB zertifizierte Gebäude sind
              ressourcenschonend gebaut, tragen weniger zu Treibhausemissionen
              bei und können großteils dem Rohstoffkreislauf wieder
              zurückgeführt werden. Damit schützen sie aktiv unsere Umwelt.
            </p>
          </Group>
          <Group style={{ flexBasis: "400px", flexGrow: 1 }}>
            <img src={dgnb} alt="DGNB" loading="lazy" />
          </Group>
        </Container>
      </Section>

      <Section>
        <img src={features} alt="" loading="lazy" />
      </Section>

      <Section>
        <Container className="media-text no-padding-right">
          <Group style={{ flexBasis: "530px", flexGrow: 1 }}>
            <h2 className="is-font-size-m is-font-family-regular">
              Sensoren an, Raum gebucht. Mit dem Smartphone rausholen, was im
              Smart Building drin ist.
            </h2>
            <p>
              Sie haben soeben die App von The Change geöffnet. Sie buchen die
              Meetingräume für heute und morgen, Sie reservieren vier Parkplätze
              für die wichtigen Besucher, jetzt noch ein kurzer Überblick über
              die ESG-Daten und ein Report über die Raumnutzung im ersten
              Quartal. Smartphone aus, an die Arbeit.
            </p>

            <p>
              The Change ist ein Smart Building der neuesten Generation. Es
              nutzt moderne Vernetzung, um den Komfort und die Nachhaltigkeit
              für alle Mitarbeiter zu verbessern. Dazu werden verschiedene
              Systeme verwendet, um Betriebsabläufe und Ressourcen bestmöglich
              zu steuern. Als Smart Ready Highrise sind dafür alle
              Voraussetzungen gegeben. Und Sie können diese mit einer App
              individualisieren. Selbstverständlich DSGVO–konform,
              selbstverständlich in einem stabilen, flächendeckenden WLAN.
            </p>

            <p>
              Ziemlich smart. Auch deswegen, weil ein großer Teil der
              Intelligenz automatisiert über Sensoren in den Büroflächen
              gesteuert wird. Ein Energiemanagementsystem überwacht und
              optimiert zudem energierelevante Prozesse wie Heizung, Kühlung
              oder die LED-Beleuchtung. Und ganz am Anfang gehört dazu Ihre
              smarte Entscheidung.
            </p>
          </Group>
          <Group style={{ flexBasis: "200px", flexGrow: 9999 }}>
            <img src={venti} alt="ventilation" loading="lazy" />
          </Group>
        </Container>
      </Section>

      <Section>
        <img src={image05} alt="" />
      </Section>

      <Section>
        <Container>
          <h2
            className="is-font-size-l is-font-family-regular"
            style={{ maxWidth: "912px", lineHeight: 1.2 }}
          >
            Wir haben hunderte zusätzliche Mitarbeiter für Sie eingestellt: Im
            großen Begrünungs-Team.
          </h2>
        </Container>
      </Section>

      <Section>
        <Container className="media-text">
          <Group style={{ flexBasis: "400px", flexGrow: 1 }}>
            <img src={lebensraum} alt="Floorplan" />
          </Group>
          <Group style={{ flexBasis: "530px", flexGrow: 1 }}>
            <p>
              Wo Menschen aufblühen und Verantwortung wurzelt – willkommen beim
              großen Grünanlagen-Konzept von The Change. Das nennen wir groß,
              weil wir nicht nur 1.700 m² Dachflächen bepflanzt haben, sondern
              weil das gesamte Konzept größer denkt.
            </p>
            <p>
              Denn es geht hier nicht nur um grüne Dekoration. Es geht um das
              Mikroklima vor Ort, es geht um Biodiversität, es geht um
              verantwortungsvolle Energie und um die Zukunft von Städten. Neben
              den Grünanlagen auf dem Boden entstehen zugleich auf den
              Dachterrassen sehr intensive Begrünungen, die als großzügige
              Freianlagen Raum für informelles Arbeiten oder für einen
              erholsamen Rückzug bieten.
            </p>
            <p>
              Durch ein smartes Wassermanagement bei den Grünanlagen werden
              Ressourcen geschont. Dafür ist das Gebäude mit 1.900 m²
              Retentionsdächern, einer Zisterne und Regenwassernutzung
              ausgestattet. Die Natur arbeitet also in The Change mit. Und freut
              sich schon auf Ihr Team.
            </p>
          </Group>
        </Container>
      </Section>

      <Crosslinks
        title="Das Beste aus zwei Welten"
        href="/de/lage"
        linkTitle="Lage"
        image={image14}
      />
    </main>
  );
};

export default Nachhaltigkeit;
