function Error() {
    return (
        <main className="site-main error-page">
            <div className="container">
                <h1>404, Site not found.</h1>
            </div>
        </main>
    )
}

export default Error