import animatedPattern from '../../assets/patterns/small_green.svg'
import staticPattern from '../../assets/patterns/small_green_static.svg'

function AnimatedPattern({ animated = true }) {
    if (animated) {
        return (
            <img className="animated-pattern" src={animatedPattern} alt="" />
        )
    } else {
        return <img className="animated-pattern" src={staticPattern} alt="" />
    }

}

export default AnimatedPattern