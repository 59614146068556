import { Link, NavLink } from 'react-router-dom';
import { useRef } from 'react';
import Lottie from 'lottie-react';
import arrowFile from '../../assets/lotties/Arrow_Animation.json';
import arrowFileWhite from '../../assets/lotties/Arrow_Animation_White.json';

function Button({ children, type = '', className = '', to = '', color = 'black' }) {

    const lottieRef = useRef(null);

    function onMouseEnter() {
        lottieRef.current.setDirection(1);
        lottieRef.current.play();
    }

    function onMouseLeave() {
        lottieRef.current.setDirection(-1);
        lottieRef.current.play();
    }

    function getArrowColor() {
        switch (color) {
            case 'black':
                return arrowFile;
            case 'white':
                return arrowFileWhite;
            default:
                return arrowFile;
        }
    }

    if (type === 'navlink') {
        return (
            <div className={`button ${type} ${className}`}>
                <NavLink to={to} onMouseEnter={onMouseEnter}
                    onMouseLeave={onMouseLeave}>
                    {children}
                    <Lottie className="button__arrow" lottieRef={lottieRef} animationData={getArrowColor()} loop={false} autoplay={false}></Lottie>
                </NavLink>
            </div>
        )
    } else {
        return (
            <div className={`button ${type} ${className}`}>
                <Link to={to} onMouseEnter={onMouseEnter}
                    onMouseLeave={onMouseLeave}>
                    {children}
                    <Lottie className="button__arrow" lottieRef={lottieRef} animationData={getArrowColor()} loop={false} autoplay={false}></Lottie>
                </Link>
            </div>
        )
    }
}

export default Button