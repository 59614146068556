import Group from "./Group";
import Section from "./Section";

function Collage({ images, phrases }) {
  return (
    <Section className="large-collage">
      <Group>
        <img src={images[3]} alt="" loading="lazy" />
      </Group>
      <Group>
        <p style={{ alignSelf: "flex-end", marginRight: "1em" }}>
          {phrases[0]}
        </p>
        <p className="medium">{phrases[1]}</p>
        <img src={images[4]} alt="" loading="lazy" />
      </Group>
      <Group>
        <p className="medium">{phrases[2]}</p>
      </Group>
      <Group>
        <p className="large" style={{ alignSelf: "flex-end" }}>
          {phrases[3]}
        </p>
      </Group>
      <Group>
        <img src={images[2]} alt="" loading="lazy" />
      </Group>
      <Group>
        <p>{phrases[4]}</p>
      </Group>
      <Group>
        <p className="medium">{phrases[5]}</p>
      </Group>
      <Group>
        <img src={images[1]} alt="" loading="lazy" />
        <p>{phrases[6]}</p>
      </Group>
      <Group>
        <img src={images[0]} alt="" loading="lazy" />
      </Group>
    </Section>
  );
}

export default Collage;
