import { Routes, Route, useLocation, Navigate } from 'react-router-dom';
import './styles/main.scss';

// LOGIN - ERROR PAGE
import Error from './pages/Error';

// ENGLISH PAGES
import SharedLayoutEN from './pages/en/SharedLayout';

import HomeEN from './pages/en/Home';
import ShowroomEN from './pages/en/Showroom';
import PropertyEN from './pages/en/Property';
import ContactEN from './pages/en/Contact';

// GERMAN PAGES
import SharedLayoutDE from './pages/de/SharedLayout';

import HomeDE from './pages/de/Home';
import Objekt from './pages/de/Objekt';
import Arbeitswelten from './pages/de/Arbeitswelten';
import Nachhaltigkeit from './pages/de/Nachhaltigkeit';
import Lage from './pages/de/Lage';
import Kontakt from './pages/de/Kontakt';
import Datenschutz from './pages/de/Datenschutz';
import Impressum from './pages/de/Impressum';

const App = () => {
    const location = useLocation();

    return (
        <>
            <Routes location={location}>

                <Route path="/de/" element={<SharedLayoutDE />}>
                    <Route index element={<HomeDE />} />
                    <Route path="objekt" element={<Objekt />} />
                    <Route path="arbeitswelten" element={<Arbeitswelten />} />
                    <Route path="nachhaltigkeit" element={<Nachhaltigkeit />} />
                    <Route path="lage" element={<Lage />} />
                    <Route path="kontakt" element={<Kontakt />} />
                    <Route path="datenschutz" element={<Datenschutz />} />
                    <Route path="impressum" element={<Impressum />} />
                </Route>

                <Route path="/en/" element={<SharedLayoutEN />}>
                    <Route index element={<HomeEN />} />
                    <Route path="showroom" element={<ShowroomEN />} />
                    <Route path="property" element={<PropertyEN />} />
                    <Route path="contact" element={<ContactEN />} />
                </Route>

                <Route exact path="/" element={<Navigate to="/de/" />} />

                <Route path="*" element={<Error />} />

            </Routes>
        </>
    );
}

export default App;