import Container from '../../components/layout/Container';

function Contact() {
    return (
        <main className="site-main contact-page">
            <Container>
                <h1>Contact</h1>
            </Container>
        </main>
    )
}

export default Contact