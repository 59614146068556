function Home01() {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 885.42 407.4">
            <g>
                <g>
                    <g className="animated-svg">
                        <path d="M90.83 150.8V7.8h-38.6V0h85.8v7.8h-38.8v143h-8.4ZM154.83 22.4c0-3.6 2.8-6.6 6.2-6.6 3.6 0 6.6 3 6.6 6.6 0 3.2-3 6.2-6.6 6.2-3.4 0-6.2-3-6.2-6.2Zm2.4 128.4V60.2h8v90.6h-8ZM196.02 150.8V60h8v17.6h.2c3.6-9.4 11.2-20 26.8-20 13.2 0 22.2 6.6 25.8 21.2h.2c4.2-10.4 12.8-21.2 28.4-21.2 18 0 29 10.8 29 35v58.2h-8V94.4c0-20.4-8-29.4-22.6-29.4-15.6 0-24.8 13-24.8 31.8v54h-8V93c0-19-8-28-21.8-28-16.2 0-25.2 13.6-25.2 33.4v52.4h-8ZM344.62 105.8c-.2 22.8 13.8 40.4 36.2 40.4 16 0 25-7.6 31.4-17.4l6.6 3.8c-7 12-18.8 20.8-38.2 20.8-26.8 0-44.6-20-44.6-48s18.2-47.6 44.4-47.6 42.2 22 42.2 44.4v3.6h-78Zm.4-6.8h69.4c-.6-17-13-33.8-34-33.8s-33.4 14.4-35.4 33.8Z" />
                    </g>
                </g>
                <g>
                    <g className="animated-svg">
                        <path d="M228.43 277.6c0-44.2 34.4-78.4 77.8-78.4 19.4 0 36 6.6 47.2 15.4v10c-11-9.6-28.4-17.4-47.8-17.4-38.8 0-68.8 30.4-68.8 70.4s30 70 68.8 70c20 0 34.4-6.4 47.4-17.6v10.2c-11.8 9.4-27.8 15.2-47.6 15.2-43 0-77-33.6-77-77.8ZM381.03 352.8v-164h8v90.6h.2c5.4-11.8 15.4-19.8 31-19.8 19.2 0 29.8 11.8 29.8 34.8v58.4h-8V296c0-20-8.6-29-24-29-17.2 0-29 11.8-29 33v52.8h-8ZM471.43 307.4c0-26.8 19.8-47.6 44.8-47.6 15.8 0 30.8 8 38.8 25.4h.2v-23h8v90.6h-8v-23h-.2c-8.2 17.4-23 25.6-38.6 25.6-25.2 0-45-20.6-45-48Zm84.4 0c0-23.8-17.2-40.4-38.2-40.4s-37.8 17.6-37.8 40.4 16.4 40.8 37.6 40.8 38.4-16.6 38.4-40.8ZM594.02 352.8v-90.6h8V280h.2c4.8-10.8 13.8-20.4 30.4-20.4 19.6 0 30.4 11.8 30.4 34.8v58.4h-8V296c0-19.8-8.8-29-24.4-29-17.2 0-28.6 12-28.6 34v51.8h-8ZM685.42 369.8l8-.6c3.4 22.2 18.6 31 37.2 31 25.2 0 37.6-16.8 37.6-45.2v-25.2h-.2c-6 14.4-19.6 25.6-39 25.6-24.8 0-43.6-20.4-43.6-48s18.8-47.6 43.4-47.6c19.4 0 32.8 11 39.2 25.4h.2v-23h8v92c0 33-14.4 53.2-45.6 53.2-22.4 0-42.2-10.2-45.2-37.6Zm83.2-62.4c0-26.2-18.2-40.4-38-40.4-21 0-37 17.6-37 40.4s15.6 40.8 37 40.8c19.8 0 38-14.2 38-40.8ZM807.42 307.8c-.2 22.8 13.8 40.4 36.2 40.4 16 0 25-7.6 31.4-17.4l6.6 3.8c-7 12-18.8 20.8-38.2 20.8-26.8 0-44.6-20-44.6-48s18.2-47.6 44.4-47.6 42.2 22 42.2 44.4v3.6h-78Zm.4-6.8h69.4c-.6-17-13-33.8-34-33.8s-33.4 14.4-35.4 33.8Z" />
                    </g>
                </g>
                <g>
                    <g className="animated-svg">
                        <path d="M7.74 252.8v-35.19H0v-6.75h7.74v-15.57c0-12.51 5.85-17.19 13.41-17.19 2.52 0 4.59.36 6.12.9v6.84c-1.44-.63-2.97-.99-4.77-.99-4.41 0-7.56 2.88-7.56 11.43v14.58h10.98v6.75H14.94v35.19h-7.2ZM29.07 231.65c0-12.06 9.09-21.96 22.14-21.96s22.23 9.63 22.23 22.14-9.18 22.23-22.32 22.23-22.05-9.63-22.05-22.41Zm37.08.18c0-9-6.21-15.66-14.94-15.66s-14.85 6.66-14.85 15.57c0 9.45 6.12 15.84 14.85 15.84s14.94-6.39 14.94-15.75ZM83.25 252.8v-41.94h7.2v5.22h.18c2.61-3.96 5.13-6.39 9.45-6.39 2.34 0 4.59.72 6.93 2.07l-3.33 6.57c-1.53-1.08-3.15-1.62-4.95-1.62-5.04 0-8.28 4.32-8.28 13.23v22.86h-7.2ZM133.11 231.74c0-12.42 8.91-21.96 20.61-21.96 6.12 0 11.52 2.43 15.48 8.28h.09v-7.2h7.2v41.94h-7.2v-7.2h-.09c-3.96 5.85-9.36 8.46-15.3 8.46-11.97 0-20.79-9.72-20.79-22.32Zm36.72.09c0-9-6.3-15.66-14.85-15.66s-14.58 6.84-14.58 15.57c0 9.18 5.85 15.84 14.58 15.84s14.85-6.57 14.85-15.75Z" />
                    </g>
                </g>
            </g>
        </svg>
    )
}

export default Home01